import React, { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { RootState } from "src/app/store"
import flashSale from "src/assets/images/flash-sale-icon.svg"
import MarkerPin from "src/assets/images/marker-pin.svg"
import MessageView from "src/assets/images/message-view.svg"
import ShoppingBag from "src/assets/images/shopping-bag.svg"
import Star from "src/assets/images/star-icon.svg"
import Truck02 from "src/assets/images/truck-02.svg"
import Truck from "src/assets/images/truck.svg"
import { configHeader } from "src/config/interceptors"
import { useToast } from "src/hooks/use-toast"
import { bookmarkProduct, deleteBookmark } from "src/services/bookmark-service"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import {
  getFlashDealTime,
  handleCheckBloomFilter,
  handlePromiseAll,
} from "src/utils/common"
import "./product-detail-custom.scss"
import {
  Address,
  LiveStreamData,
  StockItemProps,
  VariantSelected,
  VariantType,
  productDetailType,
} from "./props"

import { LivestreamCampaignControllerApi } from "@soctrip/axios-livestream-service"
import {
  AddressControllerApi,
  ProductCreateDTO,
} from "@soctrip/axios-order-service"
import {
  PromotionControllerApi,
  SoctripPromotionControllerApi,
} from "@soctrip/axios-promotion-service"
import {
  ShippingControllerApi,
  ShippingFreeRequest,
} from "@soctrip/axios-shipment-service"
import { ShopAddressControllerApi } from "@soctrip/axios-shop-service"
import PreviewVoucherModal from "src/components/modal/preview-voucher-modal"
import { VoucherType } from "src/components/modal/select-voucher-modal/types"
import CountDownCartView from "src/components/order-cart-view/shopping-cart-view/count-down-cart"
import ShareToFeed from "src/components/share-to-feed-dialog"
import {
  BOOKMARK_CATEGORY,
  LOGO,
  MAX_ORDER_PRICE,
  SERVICE,
  VOUCHER_TYPE,
} from "src/constants/common"
import { TimeListType } from "src/features/system/props"
import EmptyPage from "src/pages/empty-page"
import { convertCurrency } from "src/utils/convert-currency"
import { ProductDetailActionControls } from "../product-detail-action-controls"
import { VariationsPropsType } from "../product-detail-action-controls/props"
import SelectedVariantView from "./selected-variant-view"

export default function ProductDetailCustom(props: productDetailType) {
  const {
    sum_review,
    price_after_tax,
    original_price_after_tax,
    point,
    name,
    shopDetail,
    prices,
    stocks,
    id,
    clickIntoCommentView,
    sold,
    isFlashDeal,
    onClickVariant,
    isShopOwner,
    bloomData,
    handleGetBloomData,
    isRender,
    catalogs,
    limit_per_item,
  } = props
  const { t } = useTranslation()
  const [activeSecond, setActiveSecond] = useState<VariantType>()
  const [activeFirst, setActiveFirst] = useState<VariantType>()
  const [stockData, setStockData] = useState<StockItemProps>()
  const [isShow, setIsShow] = useState<boolean>(false)
  const [isShowVoucherShop, setIsShowVoucherShop] = useState<boolean>(false)
  const [isShowVoucherShip, setIsShowVoucherShip] = useState<boolean>(false)
  const [isInitView, setIsInitView] = useState<boolean>(false)

  const [shipVouchers, setShipVouchers] = useState<VoucherType[]>([])
  const [shopVouchers, setShopVouchers] = useState<VoucherType[]>([])

  const [liveStreamData, setLiveStreamData] = useState<LiveStreamData>()
  const [urlLiveStream, setUrlLiveStream] = useState<string>()
  const [isProductOnLiveStream, setIsProductOnLiveStream] =
    useState<boolean>(false)
  const [addressUser, setAddressUser] = useState<Address>()
  const [addressShop, setAddressShop] = useState<Address>()
  const [priceEstimate, setPriceEstimate] = useState<number>(0)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [selectVariantFirst, setSelectVariantFirst] = useState<any>()

  const [productFirst, setProductFirst] = useState<VariantType[]>([])
  const [productSecond, setProductSecond] = useState<VariantType[]>([])

  const [variationValue, setVariationValue] = useState<VariationsPropsType>()
  const [variationFirst, setVariationFirst] = useState<VariantSelected | null>(
    null
  )
  const [variationSecond, setVariationSecond] =
    useState<VariantSelected | null>(null)
  const [isClickBookMark, setIsClickBookMark] = useState<boolean>(false)

  const showToast = useToast()
  const authStore = useSelector((state: RootState) => state.auth)

  const systemStore = useSelector((state: RootState) => state.system)
  const timeFlashDeal = useMemo(() => {
    let result: undefined | TimeListType = undefined
    if (systemStore.value.length) {
      result = getFlashDealTime(systemStore.value)
    }
    return result
  }, [])

  const findStockByVariationIds = async () => {
    // 2 Variant
    if(activeFirst?.id && activeSecond?.id) {
      stocks.forEach(i => {
        if(i.variation_first_id === activeFirst?.id && i.variation_second_id === activeSecond?.id) {
          setStockData(i)
          setVariationValue({
            id: i.id,
            product_id: i.product_id,
            variation_first_name: i.variation_first?.name || "",
            variation_second_name: i.variation_second?.name || "",
            quantity: i.quantity,
            original_price: i.original_price || 0,
            price: i.price,
          })
        }
      })
    } else if(activeFirst?.id) {
      stocks.forEach(i => {
        if(i.variation_first_id === activeFirst?.id) {
          setStockData(i)
          setVariationValue({
            id: i.id,
            product_id: i.product_id,
            variation_first_name: i.variation_first?.name || "",
            variation_second_name: i.variation_second?.name || "",
            quantity: i.quantity,
            original_price: i.original_price || 0,
            price: i.price,
          })
        }
      })
    }
  }
  useEffect(() => {
    async function updateStockValue() {
      await findStockByVariationIds()
    }
    if (activeFirst) updateStockValue()
  }, [activeFirst, activeSecond])

  /* init select */
  useEffect(() => {
    const index = stocks.findIndex((item) => item.quantity > 0)
    setActiveFirst({
      value: stocks[index]?.variation_first?.name as string,
      id: stocks[index]?.variation_first?.id as string,
      index: 1,
    })
    setActiveSecond({
      value: stocks[index]?.variation_second?.name as string,
      id: stocks[index]?.variation_second?.id as string,
      index: 1,
    })
    setSelectVariantFirst(
      stocks.filter(
        (el) => el?.variation_first_id === stocks[index]?.variation_first?.id
      )
    )
  }, [stocks])
  /* Get list option Second */
  useEffect(() => {
    const result: VariantType[] = []
    if (prices && prices.length && prices[1]?.variations?.length) {
      prices[1].variations.forEach((i, index) => {
        result.push({
          value: i.name,
          id: i.id,
          index: index + 1,
          img: i?.image?.id,
          isDisabled: false,
        })
      })
    }
    setProductSecond(result)
  }, [prices && prices.length])

  useEffect(() => {
    const result: VariantType[] = []
    if (prices?.length && prices[0]?.variations?.length) {
      prices[0].variations.forEach((i, index) => {
        result.push({
          value: i.name,
          id: i.id,
          img: i?.image?.id,
          index: index + 1,
          isDisabled: false,
        })
      })
      /* setActiveFirst(result[0]) */
    } else {
      if (stocks) {
        const data = {
          id: stocks[0]?.id,
          price_after_tax: stocks[0]?.price_after_tax,
          original_price_after_tax: stocks[0]?.original_price_after_tax,
          price: stocks[0]?.price_after_tax,
          product_id: stocks[0]?.product_id,
          quantity: stocks[0]?.quantity,
          index: 1,
        }
        setStockData(data)
      }
    }

    setProductFirst(result)
  }, [prices])
  const updateDisabledOption = () => {
    if (!productSecond?.length) {
      const resultFirst: VariantType[] = []
      productFirst?.forEach((el) => {
        
        const isDisabled = stocks?.some(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (item: any) =>
            el.id === item?.variation_first_id && item.quantity === 0
        )
        resultFirst.push({ ...el, isDisabled: isDisabled ?? true })
      })
      setProductFirst(resultFirst)
    } else {
      const resultSecond: VariantType[] = []
      productSecond?.forEach((el) => {
        const isDisabled = selectVariantFirst?.some(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (item: any) =>
            item?.variation_second_id === el.id && item.quantity === 0
        )
        resultSecond.push({ ...el, isDisabled: isDisabled ?? true })
      })
      setProductSecond(resultSecond)
    }
  }

  useEffect(() => {
    if (prices?.length && productFirst?.length) updateDisabledOption()
  }, [selectVariantFirst, prices])

  useEffect(() => {
    const getAddressUser = () => {
      if (authStore.value) {
        new AddressControllerApi(configHeader(SERVICE.ORDER))
          .addressDefaultGet()
          .then((res) => {
            const data = res?.data?.data
            const converData: Address = {
              city: data?.city_id,
              district: data?.district_id,
              ward: data?.ward_id,
              name: data?.user_full_name,
              phone: data?.user_phone_number,
              street: data?.address,
            }
            setAddressUser(converData)
          })
          .catch((err) => console.log(err))
      }
    }
    getAddressUser()
  }, [authStore])

  useEffect(() => {
    const getPriceEstimatedShipping = () => {
      if (addressShop && stockData) {
        const product: ProductCreateDTO[] = [
          {
            stock_id: stockData?.id as string,
            quantity: 1,
          },
        ]
        const body: ShippingFreeRequest = {
          address_from: addressShop,
          address_to: addressUser ?? addressShop,
          products: product,
        }
        new ShippingControllerApi(configHeader(SERVICE.SHIPMENT))
          .shippingFeesPost(body)
          .then((res) => {
            const data = res?.data?.data
            if (data?.length) {
              let fee = data[0]?.total_fee || 0
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              data.forEach((i:any) => {
                const compareFee = i?.total_fee || 0
                if(fee > compareFee) fee = compareFee
              })
              setPriceEstimate(fee)
            }
          })
          .catch((err) => {
            console.log(err)
          })
      }
    }
    getPriceEstimatedShipping()
  }, [addressShop, addressUser, stockData])

  useEffect(() => {
    const getAddressShop = () => {
      new ShopAddressControllerApi(configHeader(SERVICE.SHOP))
        .shopAddressesShopShopIdGet(shopDetail?.shopId)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((res: any) => {
          const data = res?.data?.data
          const converData: Address = {
            city: data?.province?.id,
            district: data?.district?.id,
            ward: data?.ward?.id,
            name: data?.name,
            phone: data?.phone,
            street: data?.street,
          }
          setAddressShop(converData)
        })
        .catch((e) => console.log(e))
    }
    getAddressShop()
  }, [shopDetail?.shopId])

  // DETECT VOUCHER APPLY
  const isVoucherApply = (voucherData: VoucherType) => {
    if (voucherData.isInvalid) return false
    if (price_after_tax) {
      const usagePerUser = voucherData.maximum_usage_per_user_count
        ? voucherData.maximum_usage_per_user_count
        : 0

      if (
        voucherData.is_limit_usage &&
        voucherData.used_count &&
        (voucherData.used_count * 100) / usagePerUser >= 100
      )
        return false

      return price_after_tax >= voucherData.minimum_order_price
    }
    return false
  }

  // GET SHOP VOUCHERS
  const getShopVoucher = () => {
    return (
      new PromotionControllerApi(configHeader(SERVICE.PROMOTION))
        .findByObjectIdsOrElementIds(0, 50, [shopDetail?.shopId || ""], [id])
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((res: any) => {
          if (res?.data?.data?.data?.length) {
            const result: VoucherType[] = []
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            res.data.data.data.forEach((i: any) => {
              if (i.budget_voucher - i.used_amount > 0 || !i?.is_limit_budget) {
                if (i?.voucher_discount_range?.length) {
                  let isHaveData = false
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  i?.voucher_discount_range?.forEach((rangeVoucher: any) => {
                    const maxValue = rangeVoucher?.max_order_price
                      ? rangeVoucher?.max_order_price
                      : MAX_ORDER_PRICE
                    if (
                      price_after_tax > rangeVoucher?.min_order_price &&
                      price_after_tax <= maxValue
                    ) {
                      isHaveData = true
                      result.push({
                        ...i,
                        ...{
                          isLimitPercent:
                            !rangeVoucher?.is_unlimited,
                          max_discount_price:
                            rangeVoucher?.max_discount_price || 0,
                          minimum_order_price:
                            rangeVoucher?.min_order_price || 0,
                          discount_amount: rangeVoucher?.discount_amount || 0,
                          discount_percent: rangeVoucher?.discount_percent || 0,
                          discount_type: rangeVoucher?.discount_type || "",
                        },
                      })
                      return
                    }
                  })
                  if (!isHaveData) {
                    const lastMinOrder =
                      i.voucher_discount_range[
                        i.voucher_discount_range.length - 1
                      ]
                    result.push({
                      ...i,
                      ...{
                        isInvalid: true,
                        minimum_order_price: lastMinOrder?.min_order_price || 0,
                        max_order_price:
                          lastMinOrder?.max_order_price || MAX_ORDER_PRICE,
                      },
                    })
                  }
                } else {
                  result.push({ ...i, ...{ isInvalid: false } })
                }
              }
            })
            // sort list data
            const dataVoucher = [] as VoucherType[]
            result.forEach((i) => {
              if (isVoucherApply(i)) dataVoucher.push(i)
            })

            setShopVouchers(result)
          }
        })
        .catch((err) => console.log(err))
    )
  }
  // GET SHIP VOUCHERS
  const getShipVoucher = () => {
    return new SoctripPromotionControllerApi(configHeader(SERVICE.PROMOTION))
      .findSoctripVoucherByObjectIdsOrElementIds(
        0,
        50,
        [shopDetail?.shopId || ""],
        [id]
      )
      .then((res) => {
        if (res?.data?.data?.data?.length) {
          const result: VoucherType[] = []
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          res.data.data.data.forEach((i: any) => {
            if (i.budget_voucher - i.used_amount > 0 || !i?.is_limit_budget) {
              if (i?.voucher_discount_range?.length) {
                let isHaveData = false
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                i?.voucher_discount_range?.forEach((rangeVoucher: any) => {
                  const maxValue = rangeVoucher?.max_order_price
                    ? rangeVoucher?.max_order_price
                    : MAX_ORDER_PRICE
                  if (
                    price_after_tax > rangeVoucher?.min_order_price &&
                    price_after_tax <= maxValue
                  ) {
                    isHaveData = true
                    result.push({
                      ...i,
                      ...{
                        isLimitPercent:
                          !rangeVoucher?.is_unlimited,
                        max_discount_price:
                          rangeVoucher?.max_discount_price || 0,
                        minimum_order_price: rangeVoucher?.min_order_price || 0,
                        discount_percent:
                          rangeVoucher?.discount_type === "PRICE"
                            ? rangeVoucher?.discount_amount || 0
                            : rangeVoucher?.discount_percent || 0,
                        discount_type: rangeVoucher?.discount_type,
                        discount_amount: rangeVoucher?.discount_amount || 0,
                      },
                    })
                    return
                  }
                })
                if (!isHaveData) {
                  const lastMinOrder =
                    i.voucher_discount_range[
                      i.voucher_discount_range.length - 1
                    ]
                  result.push({
                    ...i,
                    ...{
                      isInvalid: true,
                      minimum_order_price: lastMinOrder?.min_order_price || 0,
                      max_order_price:
                        lastMinOrder?.max_order_price || MAX_ORDER_PRICE,
                    },
                  })
                }
              } else {
                result.push({ ...i, ...{ isInvalid: false } })
              }
            }
          })
          // sort list data
          const dataVoucher = [] as VoucherType[]
          result.forEach((i) => {
            if (isVoucherApply(i)) dataVoucher.push(i)
          })
          setShipVouchers(result)
        }
      })
      .catch((err) => console.log(err))
  }

  const chooseFirstList = async (variant: VariantType) => {
    setActiveFirst(variant)
    setVariationFirst(variant)
    setSelectVariantFirst(
      stocks.filter((el) => el?.variation_first_id === variant.id)
    )
  }

  const chooseSecondList = (variant: VariantType) => {
    setActiveSecond(variant)
    setVariationSecond(variant)
  }

  const handleBookmark = () => {
    if(isClickBookMark) return
    setIsClickBookMark(true)
    if (authStore?.value && id) {
      if (bloomData) {        
        if (!handleCheckBloomFilter(bloomData as string, id as string)) {
          bookmarkProduct(id, BOOKMARK_CATEGORY.PRODUCT)
            .then(() => {
              if (handleGetBloomData) handleGetBloomData()
              showToast({
                detail: capitalizedFirst(
                  t("product-details.add-bookmark-success")
                ),
                severity: "success",
              })
            })
            .catch((error) => {
              throw error
            }).finally(() => setIsClickBookMark(false))
        } else {
          deleteBookmark(id)
            .then(() => {
              if (handleGetBloomData) handleGetBloomData()
              showToast({
                detail: capitalizedFirst(
                  t("product-details.remove-bookmark-success")
                ),
                severity: "success",
              })
            })
            .catch((error) => {
              error
            }).finally(() => setIsClickBookMark(false))
        }
      } else {
        bookmarkProduct(id, BOOKMARK_CATEGORY.PRODUCT)
          .then(() => {
            if (handleGetBloomData) handleGetBloomData()
            showToast({
              detail: capitalizedFirst(
                t("product-details.add-bookmark-success")
              ),
              severity: "success",
            })
          })
          .catch((error) => {
            throw error
          }).finally(() => setIsClickBookMark(false))
      }
    } else {
      showToast({
        detail: t("product-details.login-before-bookmark"),
        severity: "warn",
      })
      setIsClickBookMark(false)
    }
  }
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const apis = [] as any[]
    apis.push(getShopVoucher())
    apis.push(getShipVoucher())
    apis.push(handleCheckProductLiveStream())
    handlePromiseAll(apis).then(() => {
      setIsInitView(true)
    })
  }, [])

  const getShopVoucherDetect = useMemo(() => {
    const result = [] as VoucherType[]
    if (isInitView) {
      // get data from  shop voucher
      shopVouchers.forEach((i) => result.push(i))
      // get data from ship discount voucher
      shipVouchers.forEach((i) => {
        if (i.soctrip_voucher_type?.code !== "FREE_SHIP") result.push(i)
      })
    }
    return result
  }, [shopVouchers, shipVouchers, isInitView])

  const getShipVoucherDetect = useMemo(() => {
    const result = [] as VoucherType[]
    if (isInitView) {
      // get data from ship discount voucher
      shipVouchers.forEach((i) => {
        if (i.soctrip_voucher_type?.code === "FREE_SHIP") result.push(i)
      })
    }
    return result
  }, [shipVouchers, isInitView])

  const handleShowVoucherByShop = () => {
    if (getShopVoucherDetect.length) setIsShowVoucherShop(true)
  }

  /* Get minimum range MinSpendPrice  */
  const getMinSpendPriceVoucher = (voucher: VoucherType[]) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const array_voucher: any[] = []
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    voucher.map((el: any) => {
      array_voucher.push(el.voucher_discount_range[0])
    })
    if (array_voucher.length) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return array_voucher.reduce((min: any, range: any) => {
        const minOrderPrice = range?.min_order_price
        return minOrderPrice < min?.min_order_price ? range : min
      })
    }
    return 0
  }

  const minSpendPriceShipmentVoucher = useMemo(
    () => getMinSpendPriceVoucher(getShipVoucherDetect),
    [getShipVoucherDetect]
  )
  const minSpendPriceShopVoucher = useMemo(
    () => getMinSpendPriceVoucher(getShopVoucherDetect),
    [getShopVoucherDetect]
  )

  const handleCheckProductLiveStream = async () => {
    await new LivestreamCampaignControllerApi(configHeader(SERVICE.LIVESTREAM))
      .livestreamCampaignsLiveProductsIdGet(id)
      .then((response) => {
        const data = response?.data?.data
        if (data) {
          setIsProductOnLiveStream(true)
          setLiveStreamData(data)
        } else {
          setIsProductOnLiveStream(false)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const handleGoLive = () => {
    if (authStore?.value) {
      setUrlLiveStream(
        `${window.location.origin}/shopping/livestream/room/${liveStreamData?.livestream_id}`
      )
    } else {
      showToast({ detail: "Please login before continue", severity: "warn" })
    }
  }

  const secondVariationImage = useMemo(() => {
    const result = [] as string[]
    if (activeFirst && stocks?.length) {
      stocks.forEach((i) => {
        if (i.order_first === activeFirst?.index) {
          result.push(i?.image?.id || "")
        }
      })
    }
    return result
  }, [stocks, activeFirst])
  
  const isTicket = useMemo(() => {
    let result = false
    if(catalogs.length && catalogs[0].type === "E_TICKET") result = true
    return result
  }, [catalogs])

  return (
    <>
      {name && prices ? (
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-3 rounded-3 border border-gray-200 bg-white p-3">
            <div className="flex flex-col gap-1">
              <div className="flex w-full flex-row justify-between gap-3">
                <h1
                  id="product_title"
                  className="seo-description text-20 font-semibold leading-8 text-gray-900 md:text-24"
                >
                  {name}
                </h1>

                <div className="flex gap-4px">
                  <ShareToFeed
                    isShow={isShow}
                    handleClose={() => setIsShow(false)}
                    id={id}
                    proDetail={props}
                    linkType="PRODUCT"
                    objectName="PRODUCT_SHARE"
                  />
                  <div
                    onClick={() => handleBookmark()}
                    className={`flex h-max w-max cursor-pointer items-center rounded-full bg-white p-1 ${
                      bloomData &&
                      id &&
                      handleCheckBloomFilter(bloomData as string, id as string)
                        ? "fill-yellow-400 stroke-yellow-400"
                        : "fill-none stroke-gray-800"
                    }`}
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 12 15"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M1.33331 4.5999C1.33331 3.4798 1.33331 2.91974 1.5513 2.49192C1.74305 2.1156 2.04901 1.80964 2.42533 1.61789C2.85316 1.3999 3.41321 1.3999 4.53331 1.3999H7.46665C8.58675 1.3999 9.1468 1.3999 9.57463 1.61789C9.95095 1.80964 10.2569 2.1156 10.4487 2.49192C10.6666 2.91974 10.6666 3.4798 10.6666 4.5999V13.3999L5.99998 10.7332L1.33331 13.3999V4.5999Z" />
                    </svg>
                  </div>
                  <div
                    className="flex h-max w-max cursor-pointer items-center justify-between rounded-full bg-white p-1"
                    onClick={() => setIsShow(true)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M9.52366 2.08388C9.14224 2.07424 8.7768 2.24511 8.53572 2.54713C8.31898 2.81866 8.28932 3.08219 8.27185 3.27977C8.25437 3.47735 8.25736 3.66139 8.25736 3.89414V6.92333C6.59702 7.36767 5.05943 8.21487 3.90327 9.53526C2.46446 11.1784 1.66782 13.3058 1.66675 15.5098V16.0239C1.66679 16.1969 1.71906 16.3657 1.81645 16.5072C1.91383 16.6487 2.05158 16.7562 2.21094 16.8149C2.37031 16.8736 2.54352 16.8808 2.707 16.8354C2.87048 16.7899 3.01627 16.6942 3.12451 16.5611C3.91318 15.5912 4.89788 14.8082 6.01112 14.263C6.71632 13.9176 7.4912 13.7964 8.25736 13.6585V16.1061C8.25736 16.3389 8.25437 16.5229 8.27185 16.7205C8.28932 16.9181 8.31917 17.1801 8.53573 17.4515C8.53573 17.4521 8.53573 17.4526 8.53573 17.4532C8.77673 17.7549 9.14175 17.9263 9.52366 17.9164C9.86668 17.9078 10.0813 17.7588 10.2397 17.6437C10.398 17.5287 10.5316 17.4075 10.7047 17.2561L17.6831 11.1501C17.8842 10.9741 18.1123 10.8485 18.2607 10.4273C18.3576 10.1516 18.3576 9.84873 18.2607 9.5731C18.1123 9.15202 17.8843 9.02639 17.6831 8.85031L10.7047 2.74434C10.5316 2.59286 10.398 2.47172 10.2397 2.35666C10.0814 2.2416 9.86665 2.09256 9.52366 2.08388ZM9.905 4.2588L16.4651 10.0001L9.905 15.7414V12.5824C9.90503 12.4671 9.88184 12.353 9.83685 12.2472C9.79187 12.1414 9.72607 12.0462 9.64352 11.9675C9.56098 11.8888 9.46346 11.8282 9.35703 11.7896C9.25059 11.751 9.13752 11.7351 9.02482 11.743C7.73306 11.8325 6.46849 12.1719 5.29832 12.745C4.7028 13.0367 4.14983 13.4039 3.62332 13.8046C3.89978 12.6533 4.34558 11.5541 5.131 10.6572C6.20346 9.43232 7.64615 8.61598 9.22279 8.33439C9.41373 8.30036 9.5868 8.1987 9.71158 8.04727C9.83636 7.89585 9.90485 7.70436 9.90502 7.50647L9.905 4.2588Z"
                        fill="#344054"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              <div
                id="rating-view"
                className="flex items-center justify-start gap-1"
              >
                <span className="text-14 font-medium leading-5 text-gray-500">
                  {capitalizedFirst(
                    t("global.sold-number", {
                      sold: sold,
                    })
                  )}
                </span>
                <i className="pi pi-circle-fill text-[5px] text-gray-500" />
                <div className="flex flex-row items-center gap-4px">
                  <img
                    src={Star}
                    alt="Star"
                    className="cursor-pointer"
                    onClick={clickIntoCommentView}
                  />
                  <p
                    className="cursor-pointer text-14 font-medium text-amber-400"
                    onClick={clickIntoCommentView}
                  >
                    {point}
                  </p>
                </div>

                <i className="pi pi-circle-fill text-[5px] text-gray-500" />
                <div
                  className="flex cursor-pointer flex-row text-14 font-medium text-gray-600"
                  onClick={clickIntoCommentView}
                >
                  <img
                    src={MessageView}
                    alt="MessageView"
                    className="h-20px w-20px"
                  />
                  <div className="flex flex-row gap-4px px-4px">
                    <span>{sum_review}</span>
                    {sum_review > 1 ? (
                      <span>{capitalizedFirst(t("global.reviews"))}</span>
                    ) : (
                      <span>{capitalizedFirst(t("global.review"))}</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* DISPLAY PRICE */}
            {isProductOnLiveStream ? (
              <a
                onClick={handleGoLive}
                href={urlLiveStream}
                target="_blank"
                className="livestream-bg mb-[-10px] flex w-full flex-1 cursor-pointer items-center justify-between rounded-2 py-1 px-3"
                rel="noreferrer"
              >
                <div className="flex items-center gap-2">
                  <svg
                    width="16"
                    height="12"
                    viewBox="0 0 16 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M8.16086 0.666992H4.50579C3.96915 0.666984 3.52623 0.666977 3.16544 0.696455C2.79071 0.727072 2.44624 0.792782 2.12268 0.957642C1.62092 1.2133 1.21297 1.62125 0.957307 2.12302C0.792446 2.44658 0.726737 2.79104 0.69612 3.16578C0.666642 3.52657 0.666649 3.96947 0.666657 4.50612V7.49453C0.666649 8.03117 0.666642 8.47408 0.69612 8.83488C0.726737 9.20961 0.792446 9.55408 0.957307 9.87763C1.21297 10.3794 1.62092 10.7873 2.12268 11.043C2.44624 11.2079 2.79071 11.2736 3.16544 11.3042C3.52624 11.3337 3.96914 11.3337 4.50579 11.3337H8.16084C8.69749 11.3337 9.14041 11.3337 9.50121 11.3042C9.87594 11.2736 10.2204 11.2079 10.544 11.043C11.0457 10.7873 11.4537 10.3794 11.7093 9.87763C11.8742 9.55408 11.9399 9.20961 11.9705 8.83488C11.9979 8.50023 11.9998 8.09495 12 7.60975L13.3023 8.91211C13.4298 9.03959 13.555 9.16489 13.6649 9.25682C13.7628 9.33883 13.9691 9.5033 14.2549 9.52579C14.574 9.55091 14.8858 9.42173 15.0937 9.17832C15.2799 8.96036 15.3095 8.69819 15.3207 8.57093C15.3334 8.42829 15.3334 8.25112 15.3333 8.07088V3.9297C15.3334 3.74946 15.3334 3.57227 15.3207 3.42963C15.3095 3.30237 15.2799 3.0402 15.0937 2.82224C14.8858 2.57883 14.574 2.44965 14.2549 2.47477C13.9691 2.49726 13.7628 2.66173 13.6649 2.74374C13.5551 2.83566 13.4298 2.96095 13.3024 3.08841L12 4.39081C11.9998 3.90565 11.9979 3.5004 11.9705 3.16578C11.9399 2.79104 11.8742 2.44658 11.7093 2.12302C11.4537 1.62125 11.0457 1.2133 10.544 0.957642C10.2204 0.792782 9.87594 0.727072 9.50121 0.696455C9.14041 0.666977 8.6975 0.666984 8.16086 0.666992Z"
                      fill="white"
                    />
                  </svg>
                  <div className="flex items-center text-12 font-medium leading-4 text-white">
                    {t(
                      "product-details.product-is-being-promoted-during-the-livestream"
                    )}
                  </div>
                </div>
                <div className="flex items-center">
                  <i className="pi pi-angle-right text-white"></i>
                </div>
              </a>
            ) : null}
            <div
              className={`flex flex-col items-start justify-between rounded-3 px-1 py-1 sm:items-center sm:gap-4 md:flex-row ${
                isFlashDeal
                  ? "border border-orange-dark-600 bg-orange-dark-50"
                  : "bg-gray-100"
              }`}
            >
              <div className="flex items-start gap-1">
                <div className="flex items-center justify-center gap-1">
                  <h2 className="seo-price flex flex-row text-20 font-bold text-orange-dark-600 md:text-[30px]">
                    {convertCurrency(
                      stockData?.price_after_tax || price_after_tax
                    )}
                  </h2>
                </div>
                {stockData?.price_after_tax !==
                  stockData?.original_price_after_tax &&
                price_after_tax !== original_price_after_tax ? (
                    <>
                      <div className="flex items-center justify-center gap-1">
                        <p className="text-14 font-medium leading-7 text-gray-400 line-through md:text-16">
                          {convertCurrency(
                            stockData?.original_price_after_tax ||
                            original_price_after_tax
                          )}
                        </p>
                      </div>
                    </>
                  ) : null}
              </div>
              {isFlashDeal && timeFlashDeal && (
                <div className="flex w-full flex-row items-center gap-0 md:gap-1">
                  <div className="flex items-center gap-0 sm:gap-1">
                    <img className="h-[20px] md:h-full" src={flashSale} />
                    <div className="text-[14px] font-bold leading-6 text-orange-dark-400 md:text-20">
                      {`${LOGO.NAME} flash deals`}
                    </div>
                  </div>
                  <CountDownCartView target={timeFlashDeal?.timeStampEnd} />
                </div>
              )}
            </div>
            {productFirst.length ? (
              <div className="flex flex-row items-start gap-4">
                <div className="flex h-full min-w-[72px] items-center text-14 font-medium text-gray-700">
                  <span>{prices[0]?.name}</span>
                </div>
                <div className="flex min-h-[44px] min-w-[44px] flex-wrap items-center gap-2">
                  {productFirst.map((firstList) => (
                    <SelectedVariantView
                      key={firstList.id}
                      onClickVariant={onClickVariant}
                      {...firstList}
                      isActiveColor={activeFirst?.id === firstList.id}
                      chooseFirstList={() => chooseFirstList(firstList)}
                      imageData={firstList?.img || ""}
                    />
                  ))}
                </div>
              </div>
            ) : null}
            {productSecond.length ? (
              <div className="flex flex-row items-start gap-4">
                <div className="flex h-full min-w-[72px] items-center text-14 font-medium text-gray-700">
                  <span>{prices[1]?.name}</span>
                </div>
                <div className=" flex flex-wrap items-center gap-2">
                  {productSecond.map((secondList, index) => (
                    <SelectedVariantView
                      key={secondList.id}
                      onClickVariant={onClickVariant}
                      {...secondList}
                      isActiveColor={activeSecond?.id === secondList.id}
                      chooseFirstList={() => chooseSecondList(secondList)}
                      imageData={secondVariationImage[index] || ""}
                    />
                  ))}
                </div>
              </div>
            ) : null}
            {/* Section Control */}
            <ProductDetailActionControls
              shopId={shopDetail?.shopId || ""}
              stocks={stocks || []}
              stockData={stockData}
              prices={prices || []}
              isShopOwner={isShopOwner || false}
              variationValue={variationValue}
              variationFirst={variationFirst}
              variationSecond={variationSecond}
              isRender={isRender}
              productName={name}
              isTicket={isTicket}
              limitNumber={limit_per_item || 0}
            />
          </div>
          {isInitView && !isTicket ? (
            <>
              <div className="flex flex-col gap-3 rounded-3 border border-gray-200 bg-white p-3">
                <div className="flex flex-col rounded-3 border border-gray-200 md:flex-row ">
                  <div className="flex flex-1 items-start gap-2   px-3 py-1">
                    <img src={MarkerPin} alt="MarkerPin" />
                    <div>
                      <p className="text-14 font-semibold text-gray-700 ">
                        {capitalizedFirst(t("product-details.send-from"))}
                      </p>
                      <p className="text-12 font-medium leading-18 text-gray-500">
                        {shopDetail?.location?.district &&
                          shopDetail?.location?.district}
                        {shopDetail?.location?.district &&
                          `, ${shopDetail?.location?.province}`}
                        {shopDetail?.location?.country &&
                          `, ${shopDetail?.location?.country}`}
                      </p>
                    </div>
                  </div>
                  {getShipVoucherDetect.length ? (
                    <div className="flex flex-1 items-start gap-2 border-t-[1px] border-l-[0px] border-gray-200 px-3 py-1  md:border-t-[0px] md:border-l-[1px]">
                      <img src={Truck} alt="Truck" />
                      <div>
                        <p className="text-14 font-semibold text-gray-700 ">
                          {capitalizedFirst(t("product-details.free-shipping"))}
                        </p>
                        <p className="text-12 font-medium leading-18 text-gray-500">
                          {capitalizedFirst(
                            t("product-details.free-shipping-on-orders")
                          )}
                          <span className="ml-2px">
                            {convertCurrency(
                              minSpendPriceShipmentVoucher?.min_order_price || 0
                            )}
                          </span>
                        </p>
                      </div>
                    </div>
                  ) : null}
                </div>
                {/*===== VOUCHER SHIPMENT =====*/}
                {getShipVoucherDetect.length || getShopVoucherDetect.length ? (
                  <div className="flex flex-col gap-1 rounded-3 bg-gray-100 px-3 py-2">
                    {getShipVoucherDetect.length ? (
                      <div className="flex flex-row gap-2 ">
                        <img
                          src={Truck02}
                          alt="Truck02"
                          className="h-20px w-20px"
                        />
                        <div className="flex flex-wrap  gap-4px md:flex-row">
                          <span className="text-14 font-medium leading-5 text-gray-500">
                            {capitalizedFirst(
                              t("global.estimated-shipping-price")
                            )}
                          </span>
                          <span className="text-14 font-semibold leading-5 text-gray-700">
                            {convertCurrency(priceEstimate)}
                          </span>
                          <span
                            className="cursor-pointer overflow-hidden text-ellipsis text-14 font-medium leading-5 text-blue-600"
                            onClick={() => setIsShowVoucherShip(true)}
                          >
                            {t("global.free-shipping")}
                          </span>
                          <span className="text-14 font-medium leading-5 text-gray-500">
                            {t("global.for-order-from")}
                            <span className="ml-4px text-14 font-semibold leading-5 text-gray-700">
                              {convertCurrency(
                                minSpendPriceShipmentVoucher?.min_order_price ||
                                  0
                              )}
                            </span>
                          </span>
                        </div>
                      </div>
                    ) : null}

                    {/*===== VOUCHER SHOP=====*/}
                    {getShopVoucherDetect?.length ? (
                      <div className="flex flex-row gap-2 ">
                        <img
                          src={ShoppingBag}
                          alt="ShoppingBag"
                          className="h-20px w-20px"
                        />

                        <div className="flex flex-wrap gap-4px md:flex-row">
                          <span className="text-14 font-medium leading-5 text-gray-500">
                            {capitalizedFirst(t("product-details.discount"))}
                          </span>
                          <span className="text-14 font-semibold leading-5 text-gray-700">
                            {/* check the type of 'discount_type' == PRICE then display $, otherwise display % */}
                            {getShopVoucherDetect.length
                              ? minSpendPriceShopVoucher?.discount_type ==
                                "PRICE"
                                ? `${convertCurrency(
                                  minSpendPriceShopVoucher?.discount_amount ||
                                      0
                                )}`
                                : `${minSpendPriceShopVoucher?.discount_percent}%`
                              : null}
                          </span>
                          <span className="text-14 font-medium leading-5 text-gray-500">
                            {t("product-details.for-minimum-order")}
                          </span>
                          <span className="text-14 font-semibold leading-5 text-gray-700">
                            {convertCurrency(
                              minSpendPriceShopVoucher?.min_order_price || 0
                            )}
                          </span>
                          <span className="text-14 font-medium leading-5 text-gray-500">
                            &
                          </span>
                          <span
                            className="cursor-pointer overflow-hidden text-ellipsis text-14 font-medium leading-5 text-blue-600"
                            onClick={handleShowVoucherByShop}
                          >
                            {getShopVoucherDetect.length
                              ? getShopVoucherDetect.length
                              : null}
                            {" " + t("global.more-vouchers")}
                          </span>
                          <span className="text-14 font-medium leading-5 text-gray-500">
                            {t("global.available")}
                          </span>
                        </div>
                      </div>
                    ) : null}
                  </div>
                ) : null}
              </div>
            </>
          ) : null}
        </div>
      ) : (
        <div className="h-auto w-full">
          <EmptyPage message="Not found data. Please try again later." />
        </div>
      )}
      {isShowVoucherShop && (
        <PreviewVoucherModal
          isShow={isShowVoucherShop}
          handleClose={() => setIsShowVoucherShop(false)}
          vouchers={getShopVoucherDetect}
          voucherType={VOUCHER_TYPE.VOUCHER}
          bill={stockData?.price_after_tax || price_after_tax || 0}
        />
      )}
      {isShowVoucherShip && (
        <PreviewVoucherModal
          isShow={isShowVoucherShip}
          handleClose={() => setIsShowVoucherShip(false)}
          vouchers={getShipVoucherDetect}
          voucherType={VOUCHER_TYPE.SHIPPING}
          bill={stockData?.price_after_tax || price_after_tax || 0}
        />
      )}
    </>
  )
}
