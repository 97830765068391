import http from "src/config/interceptors"

export const getBanners = (
  pageNum: number,
  pageSize: number,
  filterString: string,
  sortString?: string
) => {
  return http.get(
    `/banner/s-banners?pageNum=${pageNum}&pageSize=${pageSize}&filters=${filterString}&sorts=${sortString}`
  )
}

export const getClientBanners = (
  typeBanner: "SHOP_HERO_BANNER" | "SHOP_PROMOTION_SLIDE_BANNER"
) => {
  const localCountry = localStorage.getItem("localRegion")
    ? localStorage.getItem("localRegion")
    : "VN"
  return http.get(
    `/banner/client-s-banners/${typeBanner}?country=${localCountry}`
  )
}
