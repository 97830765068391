import { Dialog } from "primereact/dialog"
import { toDataURL } from "qrcode"
import React, { useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import trackingIcon from "src/assets/images/Icon_tracking.svg"
import soctripLogo from "src/assets/images/soctrip-logo.svg"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { Swiper as SwiperClass } from "swiper"
import { OrderInformation, OrderTicketRender } from "../../pages/order-details/props"
import { useToast } from "src/hooks/use-toast"


export default function OrderInformationDetails(data: OrderInformation) { 
  const [visible, setVisible] = useState(false) 
  const [isModalTicket, setIsModalTicket] = useState(false) 
  const [activeIndex, setActiveIndex] = useState(0)
  const [ticket, setTicket] = useState({code: "", qrCode: "", name: ""})
  const [qrCode, setQrCode] = useState<string>("")
  const showToast = useToast()
  const swiperRef = useRef<SwiperClass>()
  const copy = (text?: string) => {
    if (text) {
      navigator.clipboard.writeText(text).then(() => {
        showToast({ detail: t("purchase-history.copy-success"), severity: "success" })
      })
    }
  }

  const { t } = useTranslation()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleTranslate = (text: any) => {
    return t(text)
  }

  const formatDate = (date?: string) => {
    if (date) {
      const newTime = new Date(date)?.toLocaleString("en-GB")
      return newTime
    }
  }

  const getFullAddress = useMemo(() => {
    return [
      data?.address?.address,
      data?.address?.ward,
      data?.address?.district,
      data?.address?.city,
      data?.address?.country,
    ]
      .filter((i) => i)
      .join(", ")
  }, [data?.address])

  const convertTimestampToDateTime = (timestamp: string) => {
    const date = new Date(Number(timestamp))
    const minute = String(date.getMinutes()).padStart(2, "0")
    const hour = String(date.getHours()).padStart(2, "0")
    const day = String(date.getDate()).padStart(2, "0")
    const month = String(date.getMonth() + 1).padStart(2, "0")
    const year = date.getFullYear()

    return `${day}/${month}/${year} ${hour}:${minute}`
  }

  const tickets = useMemo(() => {
    let codes: OrderTicketRender[] = []

    data?.shops?.forEach(shop => {
      shop?.products?.forEach(product => {
        if(product?.ticketQrCodes) {
          codes = [...codes, ...product.ticketQrCodes.filter(item => (item.order)).map(item => ({...item, name: product.name}))]
        }
      })
    })
    return codes
  } , [data.shops])

  const isTicket = useMemo(() => {
    const products = data?.shops?.flatMap(shop => shop?.products)
    return products.some(product => product?.is_ticket)
  }, [data?.shops])

  const orderCode = useMemo(() => {
    const codes = data?.shops?.flatMap(shop => shop?.code)
    return codes?.length ? codes[0] : null
  }, [data.shops])

  const handleActiveTicket = async (index: number) => { 
    const ticket = tickets[index]
    const qrCode = tickets[index]?.qrCode
    const data = await toDataURL(qrCode)
    const jsonString = ticket.qrCode
    const encodedString = btoa(jsonString)
    
    setQrCode(data)
    setActiveIndex(index)
    setTicket({
      code: ticket.order,
      qrCode: encodedString,
      name: ticket.name,
    })
  }

  const handlePrevQrCode = () => {
    swiperRef.current?.slidePrev()
    if (activeIndex > 0) {
      setActiveIndex((index) => index - 1)
      handleActiveTicket(activeIndex - 1)
    }
  }

  const handleNextQrCode = () => {
    swiperRef.current?.slideNext()
    if (activeIndex < tickets.length - 1) {
      setActiveIndex((index) => index + 1)
      handleActiveTicket(activeIndex + 1)
    } 
  }

  const handleCloseQrCode = () => {
    if (!visible) return
    handleActiveTicket(0) 
    setVisible(false)
  }

  const isRect = () => {
    // V S => Rect
    const order = ticket.code
    return (order.includes("V") || order.includes("S"))
  }

  useEffect(() => {
    if(tickets.length > 0 && !ticket.code) {
      const ticket = tickets[0]
      const jsonString = ticket.qrCode
      const encodedString = btoa(jsonString)

      setTicket({
        code: ticket.order,
        qrCode: encodedString,
        name: ticket.name,
      })
    }

    if(tickets.length > 0 && !qrCode) {
      toDataURL(tickets[0].qrCode).then(data => {
        setQrCode(data)
      })
    }
  }, [tickets])

  return (
    <div className="h-full w-full">
      <div className="flex h-full w-full flex-col">
        <div className="item-center flex h-full w-full flex-col gap-3 md:flex-row">
          {/* Order information */}
          <div className={`flex h-full flex-col rounded-3 bg-white ${isTicket ? "w-full" : "md:w-1/3"}`}>
            <div className="h-[60px] w-full border-b border-gray-100 px-2 md:px-3">
              <div className="flex items-center py-3 text-18 font-semibold text-gray-800 md:px-3">
                {capitalizedFirst(handleTranslate("purchase-history.order-information"))}
              </div>
            </div>
            <div className="flex h-full w-full flex-col gap-3 px-2 py-3 md:px-3">
              <div className="flex h-full w-full flex-col gap-3 lg:flex-row">
                {/* status and order date*/}
                <div className="flex h-full w-full flex-col gap-2 lg:flex-row">
                  {/* Order ID */}
                  <div className="flex flex-1 flex-col">
                    <div className="text-14 font-medium text-gray-500">
                      { capitalizedFirst(handleTranslate(isTicket ? "purchase-history.order-code" : "purchase-history.shipment-code"))}
                    </div>
                    {isTicket ? <div className="flex w-full gap-2">
                      <p className="truncate text-14 font-normal text-gray-900">
                        {orderCode ? orderCode : "---"}
                      </p>
                      {orderCode ? (
                        <span
                          onClick={() => copy(orderCode)}
                          className="pi pi-clone cursor-pointer text-20 text-blue-700"
                        ></span>
                      ) : null}
                    </div> : <div className="flex w-full gap-2">
                      <p className="truncate text-14 font-normal text-gray-900">
                        {data?.shipment_code ? data?.shipment_code : "---"}
                      </p>
                      {data?.shipment_code ? (
                        <span
                          onClick={() => copy(data?.shipment_code)}
                          className="pi pi-clone cursor-pointer text-20 text-blue-700"
                        ></span>
                      ) : null}
                    </div>}
                    
                  </div>
                  {/* status */}
                  <div className="flex flex-1 flex-col">
                    <div className="text-14 font-medium text-gray-500">
                      {capitalizedFirst(handleTranslate("purchase-history.status"))}
                    </div>
                    <div className="flex w-full">
                      <p
                        className="truncate rounded-5 px-1 py-2px text-12 font-medium text-gray-700"
                        style={{
                          backgroundColor: data?.color,
                          color: data?.font_color,
                        }}
                      >
                        {data.status_name}          
                      </p>
                    </div>
                  </div>
                  {/* tracking */}
                  {!isTicket ? <div className="flex flex-1 justify-center flex-col">
                    <a
                      href={data?.shipment_track_url}
                      target="_blank"
                      className="flex w-full cursor-pointer items-center gap-1"
                      rel="noreferrer"
                    >
                      <img src={trackingIcon} alt="tracking-icon" />
                      <div className="text-14 font-medium text-gray-500">
                        {capitalizedFirst(handleTranslate("purchase-history.tracking"))}
                      </div>
                    </a>
                  </div> :
                    <div className="flex flex-1 flex-col">
                      <div className="text-14 font-medium text-gray-500">
                        {capitalizedFirst(handleTranslate("purchase-history.order-date"))}
                      </div>
                      <div className="flex w-full">
                        <p className="truncate text-14 font-normal text-gray-900">
                          {formatDate(data?.created_at)}
                        </p>
                      </div>
                    </div>
                  }
                  
                </div>
              </div>

              {/* Shipment tracking */}
              <div className="flex h-full w-full flex-col gap-2 justify-between lg:flex-row">
                {/* Expected date delivery */}
                {isTicket ? null : <div className="flex flex-1 flex-col">
                  <div className="text-14 font-medium text-gray-500">
                    {capitalizedFirst(handleTranslate("purchase-history.expected-date-of-delivery"))}
                  </div>
                  {
                    data?.estimatedArrivalTime && data?.estimatedArrivalTime !== "0" ? (
                      <div className="flex w-full gap-2">
                        <p className="truncate text-14 font-normal text-gray-900">
                          {data?.estimatedArrivalTime ? convertTimestampToDateTime(data?.estimatedArrivalTime) : "---"}
                        </p>
                      </div>
                    ) : null
                  }
                </div>}
                
                {/* order date */}
                {isTicket ? null : <div className={`flex w-[49%] flex-col ${isTicket ? "w-1/2": "w-full flex-1"}`}>
                  <div className="text-14 font-medium text-gray-500">
                    {capitalizedFirst(handleTranslate("purchase-history.order-date"))}
                  </div>
                  <div className="flex w-full">
                    <p className="truncate text-14 font-normal text-gray-900">
                      {formatDate(data?.created_at)}
                    </p>
                  </div>
                </div>}
                
                {/* E TICKET */}
                {isTicket ? <div className="flex w-full flex-col">
                  <div className="text-14 font-medium text-gray-500">
                    {capitalizedFirst(handleTranslate("purchase-history.e-ticket"))}
                  </div>
                  {tickets?.length ? <div>
                    <div className="flex items-center gap-1">
                      <div className="flex items-center gap-2px">
                        {tickets.slice(0, 5).map((ticket, index) => 
                          <div key={ticket.ticketCode} className="text-14 w-max">{ticket.order}{index < tickets.slice(0, 5).length - 1 && ","}</div>)}
  
                      </div>

                      {tickets.length > 5 ? 
                        <span className="text-12 px-2 py-4px bg-gray-100 rounded-5 font-medium">+ {tickets.length - 5}</span> 
                        : null
                      }
  
                      <div onClick={() => setVisible(true)}>
                        <i className="sctr-icon-qr-code-01 cursor-pointer hover:text-blue-600"></i>
                      </div>
                    </div>
                  </div> :
                    <div className="text-14 font-normal text-gray-900">---</div>
                  }
                  
                </div> : null}
              </div>

              <div className="flex flex-col justify-between gap-2 lg:flex-row">
                {/* note */}
                <div className="flex w-full flex-col">
                  <div className="text-14 font-medium text-gray-500">
                    {capitalizedFirst(handleTranslate("purchase-history.note"))}
                  </div>
                  <div className="text-14 font-normal text-gray-900">{data.note || "---"}</div>
                </div>
              </div>
            </div>
          </div>
          {/* delivery information */}
          {isTicket ? null: <div className="rounded-3 w-full md:w-2/3 bg-white">
            <div className="flex h-full w-full flex-col">
              <div className="flex h-[60px] w-full justify-between border-b border-gray-100 px-2 pr-3 md:px-3">
                <div className="flex items-center truncate py-3 text-18 font-semibold text-gray-800 md:px-3">
                  {capitalizedFirst(handleTranslate("purchase-history.delivery-information"))}
                </div>
                <div className="flex items-center justify-center">
                  <div className="rounded-4 bg-orange-dark-50 px-1 py-2px text-12 font-medium text-orange-700">
                    {data?.address?.address_type}
                  </div>
                </div>
              </div>
              <div className="flex w-full flex-col py-3 px-2 sm:gap-2 md:px-3 lg:gap-3">
                <div className="flex h-full w-full flex-col gap-2 lg:flex-row">
                  {/* name */}
                  <div className="flex w-full flex-col">
                    <div className="text-14 font-medium text-gray-500">
                      {capitalizedFirst(handleTranslate("purchase-history.name"))}
                    </div>
                    <div className="flex max-w-[180px] items-center gap-1">
                      <p className="truncate text-14 font-normal text-gray-900">
                        {data.user_full_name}
                      </p>
                    </div>
                  </div>
                  {/* status and order date*/}
                  <div className="flex w-full gap-1">
                    {/* order date */}
                    <div className="flex flex-col">
                      <div className="text-14 font-medium text-gray-500">
                        {capitalizedFirst(handleTranslate("purchase-history.phone"))}
                      </div>
                      <div className="flex w-full">
                        <p className="truncate text-14 font-normal text-gray-900">
                          {data?.user_phone_number}
                          {/* {handleMaskPhoneNumber(data.user_phone_number)} */}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* address */}
                <div className="flex w-full flex-col">
                  <div className="text-14 font-medium text-gray-500">
                    {capitalizedFirst(handleTranslate("global.address"))}
                  </div>
                  {data?.address ? (
                    <div className="truncate text-14 font-normal text-gray-900">
                      {getFullAddress}
                    </div>
                  ) : (
                    <div className="truncate text-14 font-normal text-gray-900">
                      {capitalizedFirst(handleTranslate("purchase-history.address-empty"))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>}
          
        </div>
      </div>

      <Dialog 
        draggable={false} 
        header={t("purchase-history.e-ticket")} 
        visible={isModalTicket} 
        style={{ width: "90vw", maxWidth: isRect() ? "600px" : "800px"}} 
        onHide={() => setIsModalTicket(false)}>
        {tickets?.length ? (
          <div className="h-full w-full items-center gap-2">
            <iframe
              height={isRect() ? "660px" : "364px"}
              width="100%"
              src={`${process.env.REACT_APP_IFRAME_E_TICKET}?code=${ticket.code}&qrCode=${ticket.qrCode}`}
            />
            {/* NAVIGATION */}
            <div className="relative w-full p-2">
              <div className="flex gap-1 justify-center">
                <button
                  onClick={handlePrevQrCode}
                  className={`${
                    activeIndex === 0 && "cursor-default text-gray-300"
                  } flex h-40px w-40px items-center justify-center rounded-3 border`}
                >
                  <i className="sctr-icon-chevron-left"></i>
                </button>

                <div className="flex h-40px px-10px text-14 items-center justify-center rounded-3 border">
                  <span className="text-blue-600 font-semibold">{activeIndex + 1}</span>/{tickets?.length}
                </div>

                <button
                  onClick={handleNextQrCode}
                  className={`${
                    activeIndex === tickets.length - 1 &&
                    "cursor-default text-gray-300"
                  } flex h-40px w-40px items-center justify-center rounded-3 border`}
                >
                  <i className="sctr-icon-chevron-right"></i>
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="h-auto w-full">
          </div>
        )}
      </Dialog>

      <Dialog 
        draggable={false} 
        showHeader={false} 
        visible={visible} 
        style={{ width: "90vw", maxWidth: "420px", borderRadius: "16px", overflow: "hidden" }} 
        onHide={handleCloseQrCode}
      >
        {tickets?.length ? (
          <div className="py-4 h-full w-full items-center gap-2 bg-gradient-to-b from-[#1570EF] to-[#36BFFA]">
            <div className="font-semibold  mb-4 text-white text-center text-20">
              {capitalizedFirst(handleTranslate("purchase-history.e-ticket"))}
            </div>
            <i onClick={handleCloseQrCode} className="sctr-icon-x-close text-20 text-white absolute right-4 top-5 cursor-pointer"></i>
            <div className="bg-white rounded-4 mx-4">
              <div className="py-3 px-20px border-b border-dashed">
                <div className="text-18 font-semibold">
                  {ticket.name}
                </div>
                <div className="text-14">
                  {data?.created_at && formatDate(data?.created_at)}
                </div>
              </div>
              <div className="flex flex-col items-center mb-4">
                <div className="relative h-[200px] w-[200px]">
                  <img className="w-full h-full" src={qrCode} alt="QR Code" />
                  <div className="w-6 h-6 bg-white absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
                    <img className="w-full h-full" src={soctripLogo} alt="Soctrip" />
                  </div>
                  
                </div>
                <div className="font-medium mt-1 mb-2">{ticket.code}</div>
                <button 
                  onClick={() => setIsModalTicket(true)} 
                  className="px-20px py-1 rounded-2 border border-blue-600 text-blue-600 font-semibold text-14"
                >
                  {capitalizedFirst(handleTranslate("purchase-history.view-ticket"))}
                </button>
              </div>
              
              {/* NAVIGATION */}
              <div className="relative w-full p-2">
                <div className="flex gap-1 justify-center">
                  <button
                    onClick={handlePrevQrCode}
                    className={`${
                      activeIndex === 0 && "cursor-default text-gray-300"
                    } flex h-40px w-40px items-center justify-center rounded-3 border`}
                  >
                    <i className="sctr-icon-chevron-left"></i>
                  </button>

                  <div className="flex h-40px px-10px text-14 items-center justify-center rounded-3 border">
                    <span className="text-blue-600 font-semibold">{activeIndex + 1}</span>/{tickets?.length}
                  </div>

                  <button
                    onClick={handleNextQrCode}
                    className={`${
                      activeIndex === tickets.length - 1 &&
                      "cursor-default text-gray-300"
                    } flex h-40px w-40px items-center justify-center rounded-3 border`}
                  >
                    <i className="sctr-icon-chevron-right"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="h-auto w-full">
          </div>
        )}
      </Dialog>
    </div>
  )
}
